.card-company {
  position: relative;
  width: 500px;
  background-color: #283142;
  text-align: center;
}

.card-company .semicircle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  /* Adjust the height as needed */
  background-image: linear-gradient(to right, #444d4e, #6a8f8f);
  border-radius: 0 0 50px 50px;
  /* To create a semicircle at the bottom */
  z-index: 2;
}

.card-company .title-total {
  position: relative;
  z-index: 3;
}

.card-company::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

.card-company::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* Adjust the width as needed */
  /* background-image: linear-gradient(to right, #78bbc7, #6a8f8f); */
}

.drawer-dash-monthly {
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  text-align: center;
  margin: 2px;
}

.chat .chat-content .chat-content-body .msg {
  display: block !important;
}

.msg-recipient .bubble {
  float: left !important;
}

.msg-sent .bubble {
  float: right !important;
}